import { ObjectsDrawerFormWrapper } from './EditAndAddDrawerForm.styles'
import { EditAndAddDrawerFormProps } from './EditAndAddDrawerForm.types'
import { validationObject } from './validation'
import { Box, Stack, Typography } from '@mui/material'
import { objectsApi, useCreateObjectMutation, useEditObjectMutation } from 'api/objects'
import { ObjectShort } from 'api/objects/api.types'
import { Button } from 'components/Button'
import { FieldForm } from 'components/FieldForm'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { COMMON_DOCS_NUMBER } from 'utils/constants'

export const EditAndAddDrawerForm: FC<EditAndAddDrawerFormProps> = ({ onClose, onFormChange, target }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { id, title, number, description } = target || {}
  const commonObject = number === COMMON_DOCS_NUMBER
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const initialValues: ObjectShort = useMemo(
    () => ({
      title: title || '',
      number: number || '',
      description: description || '',
    }),
    [target],
  )

  const [createObject, createObjectResponse] = useCreateObjectMutation()
  const [editObject, editObjectResponse] = useEditObjectMutation()

  const onSubmit = useCallback(
    (values: ObjectShort) => {
      const dataForRequest: ObjectShort = {
        title: values.title.trim(),
        number: values.number,
        description: values.description,
      }
      if (target) {
        editObject({
          objectId: target.id,
          body: dataForRequest,
        })
      } else {
        createObject({
          projectId,
          body: dataForRequest,
        })
      }
    },
    [target],
  )

  const { formik } = useForm({
    validationSchema: validationObject,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(createObjectResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    enqueueSnackbar('Объект успешно добавлен.', { variant: 'success' })
    onClose(false, true)
  })

  useMutationHandlers(editObjectResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    dispatch(objectsApi.util.invalidateTags(['Objects']))
    enqueueSnackbar('Объект успешно изменён.', { variant: 'success' })
    onClose(false, true)
  })

  return (
    <ObjectsDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form}>
          <Stack spacing={1.5}>
            <FieldForm version='project' helperText='' name='number' placeholder='Номер объекта' />
            <FieldForm version='project' helperText='' name='title' placeholder='Краткое наименование' />
            <FieldForm
              version='project'
              helperText=''
              name='description'
              placeholder='Полное наименование'
              multiline
              minRows={2}
            />
          </Stack>

          {/* {!commonObject && (
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ pb: 2.5 }}>
              <Box style={{ minWidth: 150 }}>
                <Typography variant='body2' component='span'>Номер объекта</Typography>
              </Box>

              <FieldForm
                value={values.number}
                version='small'
                helperText=''
                name='number'
                placeholder='000'
                type='number'
                onKeyDown={e => {
                  if (e.key === '-' || e.key === 'e') {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  setFieldValue('number', e.target.value.slice(0, 5))
                }}
                inputProps={{
                  min: '0',
                }}
                style={{ maxWidth: 90 }}
              />
            </Stack>
          )} */}

          <Stack direction='row' spacing={2}>
            <Button
              type='submit'
              disabled={isSubmitting || !dirty || Object.values(values).some((value) => !value)}
              color='success'
              size='medium'
              fullWidth
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onClose(dirty, true)}>
              Закрыть
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
    </ObjectsDrawerFormWrapper>
  )
}
