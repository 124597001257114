import { EliminationDrawer } from '../EliminationDrawer'
import { Info } from './Info'
import { Media } from './Media'
import { Parties } from './Parties'
import { FormWrapper, FormItemsWrapper, FullWrapper } from './RemarkForm.styles'
import {
  PrescriptionValuesWithId,
  RemarkAutofilledData,
  RemarkFormDialogTrigger,
  RemarkDrawerType,
  RemarkFormData,
  RemarkLocationState,
  remarkModalNotifyInfo,
  remarkNotificationInfo,
} from './RemarkForm.types'
import { validationRemark } from './validation'
import { Delete as DeleteIcon, InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { prescriptionsApi } from 'api/prescriptions'
import {
  remarksApi,
  useChangeRemarkNotificationMutation,
  useCreateRemarkMutation,
  useDeleteImagesFromRemarkMutation,
  useDeleteRemarkMutation,
  useDeleteRemarkCopyMutation,
  useEditRemarkMutation,
  useGetNextRemarkNumberQuery,
  useGetRemarkQuery,
  useLazyCheckRemarkForFilterSatisfactionQuery,
  useUploadImagesToRemarkMutation,
  useEditRemarkCopyMutation,
  useLazyCheckRemarkForEmailNotificationQuery,
} from 'api/remarks'
import { CurrentSortableColumn, RemarkCopyShort, RemarkFull, RemarkMediaComments, RemarkShort } from 'api/remarks/types'
import { Button } from 'components/Button'
import { StyledCheckbox, StyledSwitch } from 'components/FieldForm'
import { Progress } from 'components/Progress'
import { SimpleHeader } from 'components/SimpleHeader'
import { Tooltip } from 'components/Tooltip'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useFirstRender } from 'hooks/useFirstRender'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from 'store'
import { isUserHighAccessSelector, profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'
import { connectNames } from 'utils/connectNames'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const RemarkForm: FC = () => {
  const profile = useTypedSelector(profileSelector)

  const { projectId: projectIdString, remarkId: remarkIdString } = useParams()
  const projectId = Number(projectIdString)
  const remarkId = Number(remarkIdString)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess
  const [shouldAutofillDescription, setShouldAutofillDescription] = useState<boolean>(false)
  const [shouldAutofillMeasuresAndBasis, setShouldAutofillMeasuresAndBasis] = useState<boolean>(false)

  // Location
  const location = useLocation()
  const locationState: RemarkLocationState = (location?.state as RemarkLocationState) || {}
  // const { sortableColumn, filterData, prescriptionValues, shouldResetLocationState } = locationState || {}
  const formMode: 'add' | 'edit' = location.pathname.includes('/add') ? 'add' : 'edit'

  const [autofilledData, setAutofilledData] = useState<RemarkAutofilledData>({
    object: null,
    contractor: null,
  })

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => locationState, [])

  const { sortableColumn, filterData, prescriptionValues } = savedLocationState || {}

  const [createRemark, { isLoading: isCreating, ...createRemarkResponse }] = useCreateRemarkMutation()
  const [editRemark, { isLoading: isEditing, ...editRemarkResponse }] = useEditRemarkMutation()
  const [editRemarkCopy, { isLoading: isEditingCopy, ...editRemarkCopyResponse }] = useEditRemarkCopyMutation()
  const [deleteRemark, { isLoading: isDeleting, ...deleteRemarkResponse }] = useDeleteRemarkMutation()
  const [deleteRemarkCopy, { isLoading: isDeletingCopy, ...deleteRemarkCopyResponse }] = useDeleteRemarkCopyMutation()
  const [uploadImagesToRemarkRequest, { isLoading: isMediaUploading, ...uploadImagesToRemarkResponse }] =
    useUploadImagesToRemarkMutation()
  const [deleteImagesFromRemarkRequest, { isLoading: isMediaDeleting, ...deleteImagesFromRemarkResponse }] =
    useDeleteImagesFromRemarkMutation()
  const [changeRemarkNotificationMutation, { isLoading: isNotificationChanging, ...changeRemarkNotificationResponse }] =
    useChangeRemarkNotificationMutation()
  const [checkForFilterSatisfaction, { isLoading: isSatisfactionChecking, ...checkForFilterSatisfactionResult }] =
    useLazyCheckRemarkForFilterSatisfactionQuery()
  const [checkRemarkForEmailNotification, { isLoading: isEmailNotificationChecking, ...checkEmailNotificationResult }] =
    useLazyCheckRemarkForEmailNotificationQuery()

  const isQuerying = isCreating || isEditing || isEditingCopy || isMediaUploading || isMediaDeleting
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { data: remarkData, isFetching: isRemarkDataFetching } = useGetRemarkQuery(
    {
      projectId,
      remarkId: remarkId!,
    },
    {
      skip: !remarkId,
    },
  )
  const { dateStart, dateEnd, dateDone } = remarkData?.period || {}
  const { data: nextNumber, isFetching: isNextNumberFetching } = useGetNextRemarkNumberQuery(projectId, {
    skip: !!remarkId,
  })

  const {
    allCopiesExpired,
    copyNumber,
    status,
    number,
    object,
    senderCompany,
    senderUser,
    assignmentType,
    measures,
    basis,
    description,
    comment,
    contractor,
    responsible,
    prescription,
    media,
    notificationsEnabled,
    previousDateEnd,
    primary,
    hasSecondary,
    hasSecondaryInPrescription,
    contractorStatus,
    wasSent,
  } = remarkData || {}

  const parsedDateStart = parseResponseDate(dateStart).date
  const parsedDateEnd = parseResponseDate(dateEnd).date
  const parsedDateDone = parseResponseDate(dateDone).date
  const parsedPreviousDateEnd = parseResponseDate(previousDateEnd || undefined).date

  const [localNotificationsEnabled, setLocalNotificationsEnabled] = useState<boolean | null>(remarkId ? null : true)
  const [isShouldUpdateRemarkById, setIsShouldUpdateRemarkById] = useState(false)
  const isShouldNotify = useRef(false)
  const isIncludedCopy = Boolean(copyNumber && prescription)
  const isNotIncludedCopy = Boolean(copyNumber && !prescription)

  const getMediaCommentById = useMemo(() => {
    let mediaCommentById: RemarkMediaComments | null = null

    ;(media || []).forEach((item: any) => {
      if (!mediaCommentById) mediaCommentById = {}

      mediaCommentById[item.id] = item.comment || ''
    })

    return mediaCommentById
  }, [media])

  const initialValues: RemarkFormData = useMemo(
    () => ({
      status: status || 'CREATED',
      dateStart: parsedDateStart || prescriptionValues?.dateStart || new Date(),
      number: number || String(nextNumber || ''),
      senderCompany:
        senderCompany || profile.company.userCompanyName
          ? {
              id: senderCompany?.company || senderCompany?.projectMember?.id || profile.company.userCompanyName,
              value:
                senderCompany?.company || senderCompany?.projectMember?.shortName || profile.company.userCompanyName,
              type: senderCompany?.projectMember ? 'projectMember' : 'company',
            }
          : null,
      senderUser:
        senderUser?.representative || senderUser?.user || profile
          ? {
              id: senderUser?.representative?.id || senderUser?.user?.id || profile?.id || 0,
              value: senderUser?.representative?.fullName || connectNames(senderUser?.user || profile),
              subtext:
                senderUser?.representative || senderUser?.user
                  ? senderUser?.representative?.position || senderUser?.user?.position
                  : profile.company.userPosition || '',
              type: senderUser?.representative ? 'representative' : 'engineerQC',
            }
          : null,
      object: object
        ? {
            id: object?.id,
            value: object?.title,
          }
        : null,
      activityType: assignmentType
        ? {
            id: assignmentType?.id,
            value: assignmentType?.title,
          }
        : null,
      measures: measures?.rd?.name || measures?.nd?.name || measures?.custom || '',
      basis: basis || '',
      description:
        description?.custom || description?.defect
          ? {
              id: description?.defect?.id || 0,
              value: description.custom || description.defect?.title || '',
              type: description?.custom ? 'custom' : '',
            }
          : null,
      comments: comment || '',
      copyNumber: copyNumber || null,
      contractor:
        contractor?.contractor || contractor?.company || prescriptionValues?.contractor
          ? {
              id: contractor?.contractor?.id || prescriptionValues?.contractor?.id || 0,
              value:
                contractor?.contractor?.shortName || contractor?.company || prescriptionValues?.contractor?.value || '',
              type:
                (contractor?.contractor?.id && 'projectMember') ||
                (contractor?.company && 'company') ||
                (prescriptionValues?.contractor?.id && 'projectMember') ||
                'custom',
            }
          : null,

      responsible:
        responsible?.representative || responsible?.user
          ? {
              id: responsible?.representative?.id || responsible?.user?.id || 0,
              value:
                responsible?.representative?.fullName ||
                (responsible?.user &&
                  `${responsible?.user?.lastName || ''} ${responsible?.user?.firstName || ''} ${
                    responsible?.user?.middleName || ''
                  }`) ||
                '',
              type:
                (responsible?.representative?.id && 'representative') || (responsible?.user?.id && 'user') || 'custom',
              subtext: responsible?.representative?.position || responsible?.user?.position || '',
            }
          : null,

      dateEnd: parsedDateEnd || prescriptionValues?.dateEnd || null,
      dateDone: parsedDateDone,
      prescriptionNumber: prescription?.number || '',
      prescriptionDate: prescription?.period?.dateStart || '',
      notificationsEnabled: notificationsEnabled || false,
      media: media || [],
      mediaForCreate: [],
      mediaIdsToDelete: [],
      mediaCommentById: getMediaCommentById,
      newMediaCommentById: null,
      previousDateEnd: parsedPreviousDateEnd || null,
    }),
    [nextNumber, remarkData],
  )

  const uploadImagesToRemark = (id?: number) => {
    uploadImagesToRemarkRequest({
      projectId,
      remarkId: id || remarkId,
      files: values.mediaForCreate,
    })
  }

  const deleteImagesFromRemark = () => {
    deleteImagesFromRemarkRequest({
      projectId,
      remarkId,
      imagesIds: values.mediaIdsToDelete,
    })
  }

  const isDirtyWithoutStatusAndDateDone = (values: RemarkFormData): boolean => {
    const { status, dateDone, ...localInitialValues }: Partial<RemarkFormData> = { ...initialValues }
    const { status: statusValue, dateDone: dateDoneValue, ...localValues }: Partial<RemarkFormData> = { ...values }

    return !isEqual(localInitialValues, localValues)
  }

  const onSubmitForm = useCallback(
    (
      values: RemarkFormData,
      isLocalSubmitAllowed: boolean,
      addedMediaId?: number[],
      createdRemarkId?: number,
      afterMediaDeleting?: boolean,
      isNotificationRequest?: boolean,
    ) => {
      const {
        status,
        activityType,
        object,
        senderCompany,
        senderUser,
        comments,
        contractor,
        measures,
        basis,
        description,
        dateStart,
        dateEnd,
        dateDone,
        responsible,
        mediaCommentById,
        newMediaCommentById,
      } = values

      if (dateDone && status !== 'COMPLETE') {
        enqueueSnackbar(
          'Замечание имеет факт. дату устранения. Измените статус на Исполнено, либо удалите факт. дату устранения.',
          { variant: 'error' },
        )
        return
      }

      const dataForRequest: RemarkShort = {
        status,
        assignmentType: Number(activityType?.id),
        comment: comments,
        objectId: Number(object?.id),
        senderCompany: {
          company: senderCompany?.type === 'company' ? senderCompany.value : null,
          projectMember: senderCompany?.type === 'projectMember' ? Number(senderCompany.id) : null,
        },
        senderUser: {
          engineerQC: senderUser?.type === 'engineerQC' ? Number(senderUser.id) : null,
          representative: senderUser?.type === 'representative' ? Number(senderUser.id) : null,
        },
        contractor: {
          company: contractor?.type === 'company' ? contractor?.value : null,
          contractor: contractor?.type === 'projectMember' ? Number(contractor?.id) : null,
        },
        measures: {
          nd: null,
          rd: null,
          custom: measures,
        },
        basis,
        description: {
          defect: description?.type !== 'custom' ? Number(description?.id) : null,
          custom: description?.type === 'custom' ? description?.value : null,
        },
        period: {
          dateStart: formatDateForServer(dateStart),
          dateEnd: formatDateForServer(dateEnd),
          dateDone: dateDone ? formatDateForServer(dateDone) : null,
        },
        responsible: responsible?.value
          ? {
              representative: responsible?.type === 'representative' ? Number(responsible?.id) : null,
              user: responsible?.type === 'user' ? Number(responsible?.id) : null,
            }
          : null,
        mediaCommentById,
      }

      if (!isLocalSubmitAllowed) {
        if (prescription?.id && !isSubmitAllowed && isDirtyWithoutStatusAndDateDone(values)) {
          setConfirmDialogTrigger('submit')
          openConfirm()

          return
        }

        if (isNotificationRequest) {
          checkRemarkForEmailNotification({
            projectId,
            remarkId: primary || remarkId || createdRemarkId || 0,
            secondaryId: primary ? remarkId || createdRemarkId || 0 : null,
            body: dataForRequest,
          })
          return
        }
      }
      setIsSubmitAllowed(false)

      if (!remarkId) dataForRequest.notificationsEnabled = Boolean(localNotificationsEnabled)

      if (addedMediaId?.length) {
        if (!dataForRequest.mediaCommentById) dataForRequest.mediaCommentById = {}

        Object.values(newMediaCommentById || {}).forEach((newComment: string, index: number) => {
          if (dataForRequest?.mediaCommentById) dataForRequest.mediaCommentById[addedMediaId[index]] = newComment
        })
      }

      if (remarkId || createdRemarkId) {
        if (values.mediaIdsToDelete.length && !afterMediaDeleting) {
          deleteImagesFromRemark()
          return
        }

        if (
          !values.mediaIdsToDelete.length &&
          values.mediaForCreate.length &&
          !addedMediaId?.length &&
          !afterMediaDeleting
        ) {
          uploadImagesToRemark()
          return
        }

        if (primary) {
          const dataForCopyRequest: RemarkCopyShort = {
            status,
            comment: comments,
            period: {
              dateStart: formatDateForServer(dateStart),
              dateEnd: formatDateForServer(dateEnd),
              dateDone: dateDone ? formatDateForServer(dateDone) : null,
            },
            responsible: responsible?.value
              ? {
                  representative: responsible?.type === 'representative' ? Number(responsible?.id) : null,
                  user: responsible?.type === 'user' ? Number(responsible?.id) : null,
                }
              : null,
          }

          editRemarkCopy({
            projectId,
            primaryRemarkId: primary,
            remarkId: remarkId || createdRemarkId || 0,
            body: dataForCopyRequest,
          })
        } else {
          editRemark({
            projectId,
            remarkId: remarkId || createdRemarkId || 0,
            loadMedia: !!prescriptionValues,
            body: dataForRequest,
            notification: isShouldNotify.current,
          })
        }
      } else {
        createRemark({
          projectId,
          body: dataForRequest,
        })
      }
    },
    [remarkData, isSubmitAllowed, isDirtyWithoutStatusAndDateDone, localNotificationsEnabled, isShouldNotify.current],
  )

  const handleError = useCallback(() => {
    enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    createRemarkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (values.mediaForCreate.length) {
        uploadImagesToRemark(data.data.id)
        return
      }

      dispatch(remarksApi.util.invalidateTags(['Remarks']))
      enqueueSnackbar('Замечание успешно добавлено.', { variant: 'success' })
      onReturnClick(data.data)
    },
    () => handleError(),
  )

  useMutationHandlers(checkEmailNotificationResult, (data) => {
    if (data.data) {
      setConfirmDialogTrigger('notification')
      openConfirm()
    } else {
      setIsSubmitAllowed(true)
      onSubmitForm(values, true)
    }
  })

  useMutationHandlers(
    editRemarkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      dispatch(remarksApi.util.invalidateTags([remarkId ? { type: 'Remarks', id: 'REMARK_BY_ID' } : 'Remarks']))
      enqueueSnackbar('Замечание успешно изменено.', { variant: 'success' })
      onReturnClick(!remarkId ? data.data : undefined)
    },
    (data) => {
      // @ts-ignore
      if (data?.originalStatus === 409) {
        enqueueSnackbar(`Замечание имеет связанную сущность: “Предписание № ${values.prescriptionNumber}”.`, {
          variant: 'error',
        })
        // resetForm()
      } else {
        handleError()
      }
    },
  )

  useMutationHandlers(
    editRemarkCopyResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      dispatch(remarksApi.util.invalidateTags([remarkId ? { type: 'Remarks', id: 'REMARK_BY_ID' } : 'Remarks']))
      enqueueSnackbar('Повторное замечание успешно изменено.', { variant: 'success' })
      onReturnClick(!remarkId ? data.data : undefined)
    },
    (data) => {
      // @ts-ignore
      if (data?.originalStatus === 409) {
        enqueueSnackbar(`Повторное замечание имеет связанную сущность: “Предписание № ${values.prescriptionNumber}”.`, {
          variant: 'error',
        })
        // resetForm()
      } else {
        handleError()
      }
    },
  )

  useMutationHandlers(
    uploadImagesToRemarkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      onSubmitForm(values, true, data.data.addedMediaId, data.data.shortcoming.id, true)
    },
    (data) => {
      // @ts-ignore
      if (data?.originalStatus === 409) {
        enqueueSnackbar(`Замечание имеет связанную сущность: “Предписание № ${values.prescriptionNumber}”.`, {
          variant: 'error',
        })
        // resetForm()
      } else {
        handleError()
      }
    },
  )

  useMutationHandlers(
    deleteImagesFromRemarkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (!values.mediaForCreate.length) onSubmitForm(values, true, undefined, undefined, true)
      else uploadImagesToRemark()
    },
    (data) => {
      // @ts-ignore
      if (data?.originalStatus === 409) {
        enqueueSnackbar(`Замечание имеет связанную сущность: “Предписание № ${values.prescriptionNumber}”.`, {
          variant: 'error',
        })
        // resetForm()
      } else {
        handleError()
      }
    },
  )

  useMutationHandlers(
    deleteRemarkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Замечание успешно удалено.', { variant: 'success' })
      onReturnClick(undefined, undefined, true)
    },
    () => handleError(),
  )

  useMutationHandlers(
    deleteRemarkCopyResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Повторное замечание успешно удалено.', { variant: 'success' })
      onReturnClick(undefined, undefined, true)
    },
    () => handleError(),
  )

  useMutationHandlers(
    changeRemarkNotificationResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar(`Уведомления успешно ${localNotificationsEnabled ? 'включены' : 'отключены'}.`, {
        variant: 'success',
      })
      // onReturnClick()
    },
    () => handleError(),
  )

  const { formik } = useForm({
    validationSchema: validationRemark,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmitForm(values, false, undefined, undefined, undefined, isNotificationRequest)
      // setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, dirty, isValid, setTouched } = formik
  const isNotificationRequest = Boolean(
    wasSent &&
      remarkId &&
      (values.status === 'COMPLETE' || values.status === 'DISCARDED') &&
      initialValues.status !== values.status,
  )

  const [isDateEndTouched, setIsDateEndTouched] = useState(false)
  const firstRender = useFirstRender()
  useEffect(() => {
    if (prescriptionValues?.dateEnd && values.dateEnd && !isDateEndTouched && !firstRender) {
      setTouched({ dateEnd: true })
      setIsDateEndTouched(true)
    }
  }, [prescriptionValues, values])

  const onChangeNotification = (value: boolean) => {
    setLocalNotificationsEnabled(value)
    if (remarkId) {
      changeRemarkNotificationMutation({ projectId, remarkId, enabled: value })
      setIsShouldUpdateRemarkById(notificationsEnabled !== value)
    }
  }

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) onReturnClick(undefined, false)
  }, [])

  const handleEliminationExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) setOpenedDrawer(null)
  }, [])

  const handleSubmitConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        setIsSubmitAllowed(true)
        onSubmitForm(values, true)
      } else {
        isShouldNotify.current = false
      }
    },
    [values],
  )

  const handleNotificationConfirm = useCallback(
    (confirm: boolean) => {
      isShouldNotify.current = confirm

      setIsSubmitAllowed(true)
      onSubmitForm(values, true)
    },
    [values],
  )

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        if (hasSecondaryInPrescription && values.prescriptionNumber) {
          enqueueSnackbar(
            `Замечание имеет связанные сущности: “Предписание № ${values.prescriptionNumber}” и повторные замечания, добавленные в предписания.`,
            { variant: 'error' },
          )

          return
        }

        if (hasSecondaryInPrescription) {
          enqueueSnackbar('Замечание имеет связанную сущность: повторные замечания, добавленные в предписания.', {
            variant: 'error',
          })

          return
        }

        if (values.prescriptionNumber) {
          enqueueSnackbar(`Замечание имеет связанную сущность: “Предписание № ${values.prescriptionNumber}”.`, {
            variant: 'error',
          })

          return
        }

        primary
          ? deleteRemarkCopy({ projectId, primaryRemarkId: primary, remarkId })
          : deleteRemark({ projectId, remarkId })
      }
    },
    [values.prescriptionNumber, projectId, remarkId, primary, hasSecondary, hasSecondaryInPrescription],
  )

  const handleAutofillDescription = useCallback((confirm: boolean) => {
    if (confirm) {
      setShouldAutofillDescription(true)
    }
  }, [])

  const handleAutofillMeasuresAndBasis = useCallback((confirm: boolean) => {
    if (confirm) {
      setShouldAutofillMeasuresAndBasis(true)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<RemarkFormDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
    deletePrimary: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить замечание?',
      body: hasSecondary
        ? 'Удаление первичного замечания повлечет за собой безвозвратное удаление всех созданных на основании него повторных замечаний, не добавленных в предписание.'
        : 'Замечание будет удалено.',
    },
    deleteSecondary: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить повторное замечание?',
      body: 'Повторное замечание будет удалено.',
    },
    submit: {
      handleConfirm: handleSubmitConfirm,
      title: 'Изменить замечание?',
      body: (
        <Stack>
          <Typography variant='body2' color={theme.palette.text.dark}>
            Замечание включено в предписание №&nbsp;{prescription?.number}. Внесенные изменения могут требовать
            обновления печатной формы документа и уведомления подрядчика.
          </Typography>
          {isNotificationRequest && (
            <Stack direction={'row'} alignItems={'center'} margin={'8px 0 -20px 0'}>
              <StyledCheckbox id='notification' onChange={(e) => (isShouldNotify.current = e.target.checked)} />
              <label htmlFor='notification' style={{ cursor: 'pointer' }}>
                <Typography variant='body2' color={theme.palette.text.dark} marginRight={1} marginLeft={-0.5}>
                  Отправить уведомление о смене статуса
                </Typography>
              </label>
              <Tooltip
                title={remarkModalNotifyInfo}
                bgColor={theme.palette.bg.white}
                textColor={theme.palette.text.dark}
              >
                <InfoIcon fontSize='inherit' color='action' />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      ),
    },
    notification: {
      handleConfirm: handleNotificationConfirm,
      title: 'Отправить уведомление?',
      body: (
        <Typography variant='body2' color={theme.palette.text.dark}>
          Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.
        </Typography>
      ),
      denyButtonText: 'нет',
    },
    eliminationExit: { handleConfirm: handleEliminationExitConfirm },
    remarkSecondaryExit: { handleConfirm: handleEliminationExitConfirm },
    autofillDescription: {
      handleConfirm: handleAutofillDescription,
      title: 'Заполнить описание на основании данных справочника?',
      body: 'Поля Описание, Мероприятия по устранению и Основание (при наличии данных) будут заполнены релевантными значениями из справочника Дефекты с возможностью изменения.',
      denyButtonText: 'Нет',
    },
    autofillMeasuresAndBasis: {
      handleConfirm: handleAutofillMeasuresAndBasis,
      title: 'Заполнить типовые мероприятия и основание на основании данных справочника?',
      body: 'Поля Мероприятия по устранению и Основание будут заполнены релевантными значениями из справочника Дефекты с возможностью их изменения.',
      denyButtonText: 'Нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const triggerConfirmDialog = useCallback((dialogType: RemarkFormDialogTrigger) => {
    setConfirmDialogTrigger(dialogType)
    openConfirm()
  }, [])

  const onClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      setConfirmDialogTrigger('exit')
      let localDirty = dirty

      if (Object.values(autofilledData).some((autofilledItem) => autofilledItem)) {
        const localInitialValues = initialValues

        for (let key in autofilledData) {
          // @ts-ignore
          if (!localInitialValues[key]) localInitialValues[key] = autofilledData[key]
        }

        localDirty = !isEqual(localInitialValues, values)
      }

      immediately || !localDirty ? onReturnClick(undefined, false) : openConfirm()
    },
    [autofilledData, values, isShouldUpdateRemarkById],
  )

  const onDelete = useCallback(() => {
    setConfirmDialogTrigger(primary ? 'deleteSecondary' : 'deletePrimary')
    openConfirm()
  }, [primary])

  const getTitle = () => {
    let title = ''
    let prescriptionTitle = (prescriptionValues && 'Создание предписания / ') || ''
    if (prescriptionValues?.id) prescriptionTitle = 'Редактирование предписания / '
    if (prescriptionValues?.id && viewingOnly) prescriptionTitle = 'Просмотр предписания / '

    title = title + ((remarkId && viewingOnly && 'Просмотр замечания') || '')
    if (title) return prescriptionTitle + title

    title = title + ((remarkId && !viewingOnly && 'Редактирование замечания') || '')
    if (title) return prescriptionTitle + title

    title = title + ((!remarkId && 'Создание замечания') || '')
    if (title) return prescriptionTitle + title

    return ''
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    const { status, data } = checkForFilterSatisfactionResult
    if (status === 'fulfilled' && data) {
      const { sortableColumn, filterData, currentPage } = savedLocationState

      navigate(`/project/${projectId}/remarks`, {
        state: {
          sortableColumn,
          filterData,
          remarkId,
          remarksPage: currentPage,
          transaction: true,
        },
      })
    }
  }, [checkForFilterSatisfactionResult])

  const onReturnClick = (newRemark?: RemarkFull, withEdit: boolean = true, deletingMode?: boolean) => {
    if (prescriptionValues) {
      const localPrescriptionValues: Partial<PrescriptionValuesWithId> = {
        ...prescriptionValues,
      }

      if (newRemark && formMode === 'add') {
        // 1 old remarks
        if (localPrescriptionValues.remarks) {
          localPrescriptionValues.remarks.map((remark) => {
            const localIncludedObject = localPrescriptionValues?.choice?.includedObjects?.[remark?.object.id]
            if (localIncludedObject?.include.includes(remark.id)) return

            localPrescriptionValues.choice = {
              ...(localPrescriptionValues?.choice || {}),
              all: localPrescriptionValues?.choice?.all || false,
              excludedObjects: localPrescriptionValues?.choice?.excludedObjects || [],
              includedObjects: {
                ...(localPrescriptionValues?.choice?.includedObjects || {}),
                [remark?.object.id]: {
                  all: false,
                  exclude: localIncludedObject?.exclude || [],
                  include: [...(localIncludedObject?.include || []), remark.id],
                },
              },
            }
          })
        }

        // 2 new remark
        const { choice } = localPrescriptionValues
        const localIncludedObject = choice?.includedObjects?.[newRemark?.object.id]

        localPrescriptionValues.choice = {
          ...(choice || {}),
          all: choice?.all || false,
          excludedObjects: choice?.excludedObjects || [],
          includedObjects: {
            ...(choice?.includedObjects || {}),
            [newRemark?.object.id]: {
              all: localIncludedObject?.all || false,
              exclude: localIncludedObject?.exclude || [],
              include: [...(localIncludedObject?.include || []), newRemark.id],
            },
          },
        }
      } else if (formMode === 'edit' && withEdit) {
        dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))
      }

      delete localPrescriptionValues.id

      const url = prescriptionValues?.id
        ? `/project/${projectId}/prescriptions/edit/${prescriptionValues?.id}`
        : `/project/${projectId}/prescriptions/add`

      navigate(url, {
        state: {
          sortableColumn,
          filterData,
          prescriptionValues: formMode === 'add' ? localPrescriptionValues : null,
          // prescriptionValues: (withEdit && formMode !== 'add') ? null : localPrescriptionValues,
          currentPage: savedLocationState.prescriptionsPage,
          transaction: true,
        },
      })
    } else {
      const { sortableColumn, filterData, currentPage } = savedLocationState

      const appliedFilter = Object.values(filterData || {}).some((filterableValue) => filterableValue)
        ? filterData
        : null
      if (appliedFilter && remarkId && checkForFilterSatisfactionResult.status !== 'fulfilled' && !deletingMode) {
        checkForFilterSatisfaction({
          projectId,
          remarkId,
          filter: appliedFilter,
          sort: sortableColumn as CurrentSortableColumn,
        })
      } else {
        navigate(`/project/${projectId}/remarks`, {
          state: {
            sortableColumn,
            filterData,
            remarkId,
            remarksPage: currentPage,
            transaction: true,
          },
        })
      }
    }

    if (isShouldUpdateRemarkById) {
      dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARK_BY_ID' }]))
    }
  }

  const [openedDrawer, setOpenedDrawer] = useState<RemarkDrawerType | null>(null)

  const onDrawerOpen = useCallback((drawerType: RemarkDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback((dialogType: RemarkFormDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) setOpenedDrawer(null)
    else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  return (
    <>
      {(remarkId && isRemarkDataFetching) || isNextNumberFetching ? (
        <Progress />
      ) : (
        <Stack width={'100%'} bgcolor={theme.palette.bg.white}>
          <SimpleHeader title={getTitle()} onClose={() => onClose(dirty, false)} repeat={!!copyNumber}>
            <Stack direction={'row'} spacing={2}>
              {!viewingOnly && (
                <Stack direction={'row'} alignItems={'center'}>
                  <Tooltip
                    title={remarkNotificationInfo}
                    bgColor={theme.palette.bg.white}
                    textColor={theme.palette.text.dark}
                  >
                    <InfoIcon fontSize='inherit' color='action' />
                  </Tooltip>
                  <Typography variant='body2' color={theme.palette.secondary.dark} marginLeft={0.5}>
                    Уведомления
                  </Typography>
                  <StyledSwitch
                    checked={
                      localNotificationsEnabled !== null ? localNotificationsEnabled : values.notificationsEnabled
                    }
                    onChange={(e) => onChangeNotification(e.target.checked)}
                  />
                </Stack>
              )}
              {formMode === 'edit' && (
                <Button disabled={dirty} size='medium' onClick={() => onDrawerOpen('elimination')}>
                  Устранение
                </Button>
              )}
            </Stack>
          </SimpleHeader>
          <FormWrapper>
            <FormikProvider value={formik}>
              <Stack component={Form}>
                <FullWrapper>
                  <FormItemsWrapper>
                    <Info
                      viewingOnly={viewingOnly}
                      dirty={dirty}
                      formMode={formMode}
                      setAutofilledData={setAutofilledData}
                      openedDrawer={openedDrawer}
                      onDrawerOpen={onDrawerOpen}
                      onDrawerClose={onDrawerClose}
                      allCopiesExpired={allCopiesExpired}
                      isIncludedCopy={isIncludedCopy}
                      isNotIncludedCopy={isNotIncludedCopy}
                      primaryId={primary || null}
                      eliminationStatus={contractorStatus}
                    />

                    <Parties
                      viewingOnly={viewingOnly}
                      formMode={formMode}
                      triggerConfirmDialog={triggerConfirmDialog}
                      shouldAutofillDescription={shouldAutofillDescription}
                      setShouldAutofillDescription={setShouldAutofillDescription}
                      shouldAutofillMeasuresAndBasis={shouldAutofillMeasuresAndBasis}
                      setShouldAutofillMeasuresAndBasis={setShouldAutofillMeasuresAndBasis}
                    />

                    <Media viewingOnly={viewingOnly} />
                  </FormItemsWrapper>

                  <Stack direction='row' spacing={2} justifyContent={'flex-end'} paddingRight={1.5}>
                    {!viewingOnly && (
                      <>
                        {remarkId && !prescriptionValues ? (
                          <Button
                            onClick={onDelete}
                            icon={true}
                            size='medium'
                            color='error'
                            variant='outlined'
                            style={{ maxWidth: '100%' }}
                          >
                            <DeleteIcon style={{ fill: theme.palette.error.main }} />
                            <Typography color={theme.palette.error.main} marginLeft={1}>
                              Удалить {!!copyNumber && 'повторное'} замечание
                            </Typography>
                          </Button>
                        ) : null}
                        <Button
                          type='submit'
                          disabled={!dirty || !isValid || isDeleting}
                          color='success'
                          size='medium'
                          loading={isFormLoading}
                        >
                          Сохранить
                        </Button>
                      </>
                    )}
                    <Button size='medium' onClick={() => onClose(dirty, false)}>
                      Закрыть
                    </Button>
                  </Stack>

                  <ConfirmDialog />
                </FullWrapper>
              </Stack>
            </FormikProvider>
          </FormWrapper>

          <EliminationDrawer
            isOpen={openedDrawer === 'elimination'}
            onClose={(dirty, immediately) => onDrawerClose('eliminationExit', dirty, immediately)}
            remarkStatus={initialValues.status}
            remarkDateStart={values.dateStart}
            primaryId={primary}
          />
        </Stack>
      )}
    </>
  )
}
