import { UserManagementWrapper } from './UserManagement.styles'
import { UserManagementProps } from './UserManagement.types'
import { UserForm } from './components/UserForm'
import { UserTopBar } from './components/UserTopBar'
import { PublicUserProfile } from 'api/profile/types'
import { useBanUserInModuleMutation } from 'api/users'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import useSearchParams from 'hooks/useSearchParams'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const UserManagement: React.FC<UserManagementProps> = ({ profile = {} as PublicUserProfile }) => {
  const { t } = useTranslation('user')
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const isEditUser = !!profile?.company
  const userId = profile.id
  const companyId = profile?.company?.companyID

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit')

  const searchHandlers = useSearchParams()

  const onFormChange = (dirty: boolean) => {
    setIsFormDirty(dirty)
  }

  const [banUserInModule, banUserInModuleResponse] = useBanUserInModuleMutation()

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      navigateBack()
    }
  }, [])

  const handleDeleteConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      banUserInModule({ userId, companyId })
    }
  }, [])

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить пользователя?',
      body: 'Пользователь будет удален из модуля Quality Control.',
    },
    exit: {
      handleConfirm: handleExitConfirm,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onReturnClick = useCallback(() => {
    setConfirmDialogTrigger('exit')

    isFormDirty ? openConfirm() : navigateBack()
  }, [isFormDirty])

  const onDeleteClick = useCallback(() => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }, [])

  const navigateBack = () => {
    navigate('/administration/users' + searchHandlers.getSearch())
  }

  useMutationHandlers(banUserInModuleResponse, () => {
    navigateBack()
    enqueueSnackbar(t('success.profileDelete'), {
      variant: 'success',
    })
  })

  return (
    <>
      <UserManagementWrapper flex={1}>
        <UserTopBar onReturnClick={onReturnClick} onDeleteClick={onDeleteClick} isEditUser={isEditUser} />
        <UserForm userProfileToChange={profile} isEditUser={isEditUser} onFormChange={onFormChange} />
      </UserManagementWrapper>
      <ConfirmDialog />
    </>
  )
}
