import { RemarkFormData } from '../RemarkForm.types'
import { Wrapper } from './Parties.styles'
import { descriptionTooltipTxt } from './Parties.text'
import { PartiesProps } from './Parties.types'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useGetDropdownActivityTypesQuery, useLazyGetDescriptionsForRemarkQuery } from 'api/remarks'
import { CustomSelect } from 'components/CustomSelect'
import { CustomSelectValue } from 'components/CustomSelect/CustomSelect.types'
import { FieldForm } from 'components/FieldForm'
import { Tooltip } from 'components/Tooltip'
import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const Parties: FC<PartiesProps> = ({
  viewingOnly,
  formMode,
  triggerConfirmDialog,
  shouldAutofillDescription,
  setShouldAutofillDescription,
  shouldAutofillMeasuresAndBasis,
  setShouldAutofillMeasuresAndBasis,
}) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const isAddingMode = formMode === 'add'
  // const [assignmentTypeId, setAssignmentTypeId] = useState<number | undefined>(undefined)
  const { values, setFieldValue, setTouched } = useFormikContext<RemarkFormData>()
  const { data: activityTypesDropdown } = useGetDropdownActivityTypesQuery({ projectId }, { skip: viewingOnly })
  const [getDescriptionsForRemark, DescriptionsForRemarkResult] = useLazyGetDescriptionsForRemarkQuery()
  const { data: descriptions } = DescriptionsForRemarkResult || {}
  const descriptionsList = descriptions?.data || []
  const [shouldCheckForAutofillDescription, setShouldCheckForAutofillDescription] = useState<boolean>(false)
  const isCopy = !!values.copyNumber

  const handleAssignmentTypeSelection = useCallback((value: CustomSelectValue) => {
    setShouldCheckForAutofillDescription(true)
  }, [])

  useEffect(() => {
    if (
      descriptionsList.length === 1 &&
      shouldCheckForAutofillDescription
      // descriptionsList[0].id !== values.description?.id
    ) {
      triggerConfirmDialog('autofillDescription')
      setShouldCheckForAutofillDescription(false)
    }
  }, [descriptionsList])

  useEffect(() => {
    if (shouldAutofillDescription) {
      setFieldValue('description', { id: descriptionsList[0].id, value: descriptionsList[0].text })
      setFieldValue('measures', descriptionsList[0].data.measures || '')
      setFieldValue('basis', descriptionsList[0].data.basis || '')

      setShouldAutofillDescription(false)
    } else {
      setTouched({ description: true, measures: true, basis: true })
    }
  }, [shouldAutofillDescription])

  useEffect(() => {
    if (shouldAutofillMeasuresAndBasis) {
      // @ts-ignore
      setFieldValue('measures', values.description?.type.measures)
      // @ts-ignore
      setFieldValue('basis', values.description?.type.basis)

      setShouldAutofillMeasuresAndBasis(false)
    } else {
      setTouched({ measures: true, basis: true })
    }
  }, [shouldAutofillMeasuresAndBasis])

  useEffect(() => {
    getDescriptionsForRemark({ projectId, assignmentId: Number(values.activityType?.id) })
  }, [values.activityType])

  const handleDescriptionSelection = useCallback((value: any) => {
    if (value.type.measures || value.type.basis) {
      triggerConfirmDialog('autofillMeasuresAndBasis')
    }
  }, [])

  return (
    <Wrapper>
      <Stack spacing={2}>
        <Stack>
          <Stack direction={'row'}>
            <Typography variant='subtitle1' textAlign={'start'} marginBottom={'6px'} color={theme.palette.text.dark}>
              Вид работ
            </Typography>
            <Typography fontSize={14} color={theme.palette.error.main}>
              &nbsp;*
            </Typography>
          </Stack>
          <CustomSelect
            name='activityType'
            variant='textarea'
            list={activityTypesDropdown?.data || []}
            placeholder={!viewingOnly && !isCopy ? 'Выберите из списка' : ''}
            readOnly={viewingOnly || isCopy}
            isSearch={true}
            style={{
              textAlign: 'start',
            }}
            handleValueSelection={handleAssignmentTypeSelection}
          />
        </Stack>
        <Stack>
          <Stack direction={'row'} marginBottom={'6px'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'}>
              <Typography variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
                Описание
              </Typography>

              <Typography fontSize={14} color={theme.palette.error.main}>
                &nbsp;*
              </Typography>
            </Stack>
            <Tooltip title={descriptionTooltipTxt} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
              <InfoIcon fontSize='inherit' color='action' />
            </Tooltip>
          </Stack>
          <CustomSelect
            name='description'
            variant='textarea'
            list={descriptionsList}
            placeholder={!viewingOnly && !isCopy ? 'Выберите из списка' : ''}
            readOnly={viewingOnly || isCopy}
            isSearch={true}
            style={{
              textAlign: 'start',
            }}
            free
            handleValueSelection={handleDescriptionSelection}
          />
        </Stack>
        <Stack>
          <Stack direction={'row'}>
            <Typography variant='subtitle1' textAlign={'start'} marginBottom={'6px'} color={theme.palette.text.dark}>
              Мероприятия по устранению
            </Typography>
            <Typography fontSize={14} color={theme.palette.error.main}>
              &nbsp;*
            </Typography>
          </Stack>
          <FieldForm
            version='project'
            name='measures'
            placeholder={!viewingOnly && !isCopy ? 'Укажите мероприятия по устранению' : ''}
            multiline
            helperText=''
            inputProps={{
              readOnly: viewingOnly || isCopy,
              style: {
                minHeight: '140px',
              },
            }}
          />
        </Stack>
        <Stack>
          <Stack direction={'row'}>
            <Typography variant='subtitle1' textAlign={'start'} marginBottom={'6px'} color={theme.palette.text.dark}>
              Основание
            </Typography>
          </Stack>
          <FieldForm
            version='project'
            name='basis'
            placeholder={!viewingOnly && !isCopy ? 'Укажите основание замечания' : ''}
            multiline
            helperText=''
            inputProps={{
              readOnly: viewingOnly || isCopy,
              style: {
                minHeight: '40px',
              },
            }}
          />
        </Stack>
        <Stack>
          <Typography variant='subtitle1' textAlign={'start'} marginBottom={'6px'} color={theme.palette.text.dark}>
            Комментарии
          </Typography>
          <FieldForm
            version='project'
            name='comments'
            multiline
            placeholder={!viewingOnly ? 'Комментарий' : ''}
            inputProps={{
              readOnly: viewingOnly,
              style: {
                minHeight: '60px',
              },
            }}
          />
        </Stack>
      </Stack>
    </Wrapper>
  )
}
