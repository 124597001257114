import { pragmaModuleLabel } from '../../ProjectIntegrationDrawer.types'
import { SlideWrapper, StyledPhoneFieldForm } from './IntegrationRequestSlide.styles'
import {
  IntegrationRequestFormData,
  IntegrationRequestSlideProps,
  integrationRequestEmptyValues,
} from './IntegrationRequestSlide.types'
import { validationIntegrationRequest } from './validation'
import { ClickAwayListener, Slide, Stack, Typography } from '@mui/material'
import { useSendRequestIntegrationMutation } from 'api/integrations'
import { RequestIntegration } from 'api/integrations/types'
import { Button } from 'components/Button'
import { FieldForm } from 'components/FieldForm'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { MandatoryNotice } from 'pages/Remarks/components/RemarkForm/components/FieldItem/FieldItem.styles'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const IntegrationRequestSlide: FC<IntegrationRequestSlideProps> = ({
  isOpen,
  onClose,
  isConfirmDialogOpened,
  projectId,
  module,
}) => {
  const profile = useTypedSelector(profileSelector)
  const [sendRequestIntegration, { isLoading: isSendingRequest, ...sendRequestIntegrationResponse }] =
    useSendRequestIntegrationMutation()
  const { enqueueSnackbar } = useSnackbar()

  const isQuerying = isSendingRequest
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  useEffect(() => {
    if (isOpen) resetForm()
  }, [isOpen])

  const initialValues: IntegrationRequestFormData = useMemo(
    () => ({
      phone: profile.phone || '',
      email: profile.email || '',
      message: '',
    }),
    [profile],
  )

  const onSubmit = useCallback(
    (values: IntegrationRequestFormData) => {
      const { phone, email, message } = values

      const dataForRequest: RequestIntegration = {
        phone,
        email,
        message,

        dashboardModule: 'Pragma.QC',
        externalModule: pragmaModuleLabel[module],
        name: profile.firstName,
      }

      sendRequestIntegration({ projectId, body: dataForRequest })
    },
    [profile, projectId, module],
  )

  const { formik } = useForm({
    validationSchema: validationIntegrationRequest,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty: dirtyByAutofilled, isSubmitting, isValid, resetForm } = formik

  const dirtyByEmpty = useMemo(() => {
    return !isEqual(values, integrationRequestEmptyValues)
  }, [values])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    sendRequestIntegrationResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Заявка успешно отправлена.', { variant: 'success' })
      setIsFormLoading(false)
      onClose(false, true)
    },
    () => handleError(),
  )

  const onClickAway = (e: any) => {
    if (isOpen && !isConfirmDialogOpened) onClose(dirtyByAutofilled)
  }

  return (
    <ClickAwayListener mouseEvent='onMouseUp' touchEvent='onTouchStart' onClickAway={onClickAway}>
      <Slide in={isOpen} direction='up' timeout={300}>
        <SlideWrapper height={'495px'}>
          <Typography variant='body2' fontSize={14} color={theme.palette.text.dark} textAlign={'center'}>
            Оставьте заявку на подключение модуля <b>{pragmaModuleLabel[module!]}</b>. Мы свяжемся с Вами в ближайшее
            время.
          </Typography>

          <FormikProvider value={formik}>
            <Stack component={Form} height={'100%'}>
              <Stack spacing={2} height={'100%'}>
                <Stack spacing={0.5}>
                  <Typography paddingLeft={'10px'} variant='body2' fontSize={12}>
                    Телефон<MandatoryNotice>&nbsp;*</MandatoryNotice>
                  </Typography>
                  <StyledPhoneFieldForm name='phone' placeholder='Укажите номер телефона' helperText='' />
                </Stack>

                <Stack spacing={0.5}>
                  <Typography paddingLeft={'10px'} variant='body2' fontSize={12}>
                    E-mail<MandatoryNotice>&nbsp;*</MandatoryNotice>
                  </Typography>
                  <FieldForm
                    version='project'
                    name='email'
                    placeholder='Укажите почтовый адрес'
                    helperText=''
                    fullWidth={false}
                    inputProps={{
                      style: { padding: '5px 8px' },
                    }}
                  />
                </Stack>

                <FieldForm
                  version='project'
                  name='message'
                  placeholder={'Комментарий'}
                  multiline
                  helperText=''
                  inputProps={{
                    style: {
                      height: '89px',
                      overflow: 'auto',
                      padding: '5px 8px',
                    },
                  }}
                />
              </Stack>

              <FormButtonWrapper padding={2.5} spacing={1} margin={'-20px'}>
                <Stack direction='row' spacing={2} width={'100%'}>
                  <Button
                    type='submit'
                    disabled={!dirtyByEmpty || !isValid}
                    loading={isFormLoading}
                    color='success'
                    size='medium'
                    fullWidth
                  >
                    Отправить
                  </Button>
                  <Button size='medium' fullWidth onClick={() => onClose(dirtyByAutofilled)}>
                    Закрыть
                  </Button>
                </Stack>
              </FormButtonWrapper>
            </Stack>
          </FormikProvider>
        </SlideWrapper>
      </Slide>
    </ClickAwayListener>
  )
}
