// Status
import { LegendColors } from 'core/types/global'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
} from 'utils/constants'

export type PrescriptionStatus = 'CREATED' | 'COMPLETE' | 'SENT' | 'DISCARDED'
export type PrescriptionStatusRu = 'Создано' | 'Исполнено' | 'Направлено' | 'Снято'

export const prescriptionStatuses: PrescriptionStatusRu[] = ['Создано', 'Исполнено', 'Направлено', 'Снято']
export const prescriptionStatusesEn: PrescriptionStatus[] = ['CREATED', 'SENT', 'DISCARDED', 'COMPLETE']

export const prescriptionStatusRuByEn: Record<PrescriptionStatus, PrescriptionStatusRu> = {
  CREATED: 'Создано',
  SENT: 'Направлено',
  COMPLETE: 'Исполнено',
  DISCARDED: 'Снято',
}

export const prescriptionStatusEnByRu: Record<PrescriptionStatusRu, PrescriptionStatus> = {
  Создано: 'CREATED',
  Направлено: 'SENT',
  Исполнено: 'COMPLETE',
  Снято: 'DISCARDED',
}

export const prescriptionStatusesColor: Record<PrescriptionStatus, LegendColors> = {
  CREATED: PRESCRIPTION_CREATED_STATUS_COLOR,
  SENT: PRESCRIPTION_SENT_STATUS_COLOR,
  COMPLETE: PRESCRIPTION_COMPLETE_STATUS_COLOR,
  DISCARDED: PRESCRIPTION_DISCARDED_STATUS_COLOR,
}

// Inspection
export type PrescriptionInspection = 'CURRENT' | 'PLANNED' | 'INSPECTION' | 'TARGET'
export type PrescriptionInspectionRu = 'Текущее' | 'Плановое' | 'Инспекционное' | 'Целевое'

export const prescriptionInspectionTypes: PrescriptionInspectionRu[] = [
  'Текущее',
  'Плановое',
  'Инспекционное',
  'Целевое',
]

export const prescriptionInspectionRuByEn: Record<PrescriptionInspection, PrescriptionInspectionRu> = {
  CURRENT: 'Текущее',
  PLANNED: 'Плановое',
  INSPECTION: 'Инспекционное',
  TARGET: 'Целевое',
}

export const prescriptionInspectionEnByRu: Record<PrescriptionInspectionRu, PrescriptionInspection> = {
  Текущее: 'CURRENT',
  Плановое: 'PLANNED',
  Инспекционное: 'INSPECTION',
  Целевое: 'TARGET',
}

// Type
export type PrescriptionType = 'CONTINUE_WORK' | 'PAUSE_WORK'
export type PrescriptionTypeRu = 'Без остановки работ' | 'С остановкой работ'

export const prescriptionTypes: PrescriptionTypeRu[] = ['Без остановки работ', 'С остановкой работ']

export const prescriptionTypeRuByEn: Record<PrescriptionType, PrescriptionTypeRu> = {
  CONTINUE_WORK: 'Без остановки работ',
  PAUSE_WORK: 'С остановкой работ',
}

export const prescriptionTypeEnByRu: Record<PrescriptionTypeRu, PrescriptionType> = {
  'Без остановки работ': 'CONTINUE_WORK',
  'С остановкой работ': 'PAUSE_WORK',
}

// Sorting
export type SortableColumn =
  | 'status'
  | 'sender_company'
  | 'contractor'
  | 'period_dateStart'
  | 'period_dateEnd'
  | 'period_dateDone'
export type SortingOrder = null | 'DESC' | 'ASC'

export const sortableColumns: SortableColumn[] = [
  'status',
  'sender_company',
  'contractor',
  'period_dateStart',
  'period_dateEnd',
  'period_dateDone',
]
export const sortingOrders: SortingOrder[] = [null, 'DESC', 'ASC']

// Filter
export type PrescriptionFilterableColumn =
  | 'status'
  | 'sender_company'
  | 'sender_user'
  | 'contractor'
  | 'period_dateStart'
  | 'period_dateEnd'
  | 'period_dateDone'
  | 'type'
  | 'author'
  | 'receiver'
  | 'execution_control'
  | 'number'

export const filterableColumns: PrescriptionFilterableColumn[] = [
  'status',
  'sender_company',
  'sender_user',
  'contractor',
  'period_dateStart',
  'period_dateEnd',
  'period_dateDone',
  'type',
  'author',
  'receiver',
  'execution_control',
  'number'
]

export const rangingDates: PrescriptionFilterableColumn[] = ['period_dateStart', 'period_dateEnd', 'period_dateDone']

export interface FilterRange {
  after: string
  before: string
}
