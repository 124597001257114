import { DocumentRemarkItem } from '../DocumentViewingForm/components/DocumentViewingItem/components/DocumentRemarkItem'
import { SelectableRemarkWrapper } from './DocumentCreationForm.styles'
import { DocumentCreationFormProps, DocumentFormData } from './DocumentCreationForm.types'
import { validationDocument } from './validation'
import { ErrorOutline as ErrorOutlineIcon, EventAvailable as DateIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import {
  prescriptionsApi,
  useCreateEliminationResultMutation,
  useGetEliminationResultSelectableRemarksQuery,
  useGetNextEliminationResultNumberQuery,
} from 'api/prescriptions'
import { EliminationResultShort } from 'api/prescriptions/types'
import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { FieldForm, StyledCheckbox } from 'components/FieldForm'
import { Progress } from 'components/Progress'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { RemarkWarning } from 'pages/Prescriptions/components/PrescriptionsForm/components/Remarks/Remarks.styles'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { theme } from 'styles/theme'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export const DocumentCreationForm: FC<DocumentCreationFormProps> = ({
  onFormChange,
  onClose,
  prescriptionDateStart,
  onChangeTab,
}) => {
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const { data: nextNumber, isLoading: isNextNumberLoading } = useGetNextEliminationResultNumberQuery({
    projectId,
    prescriptionId,
  })
  const [createEliminationResult, { isLoading: isCreating, ...createEliminationResultResponse }] =
    useCreateEliminationResultMutation()

  const isQuerying = isCreating
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { queryData, onScrollWithInfiniteLoad } = useInfiniteScroll({
    api: prescriptionsApi,
    tagName: 'Prescriptions',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetEliminationResultSelectableRemarksQuery,
    arg: {
      projectId,
      prescriptionId,
    },
  })
  const { data = [] } = queryData.data || {}
  const { isLoading: isSelectableRemarksLoading } = queryData || {}

  const initialValues: DocumentFormData = useMemo(
    () => ({
      prescriptionDateStart,
      date: new Date(),
      number: nextNumber || '',
      comment: '',

      isAnyRemarksSelected: false,
      externalAllRemarks: false,
      internalAllRemarks: false,
      includedRemarks: [],
      excludedRemarks: [],
    }),
    [nextNumber],
  )

  const onSubmit = useCallback(
    (values: DocumentFormData) => {
      const {
        date,
        number,
        comment,

        internalAllRemarks,
        includedRemarks,
        excludedRemarks,
      } = values

      const dataForRequest: EliminationResultShort = {
        date: formatDateForServer(date),
        number: number !== nextNumber ? number : null,
        comment: comment || null,
        shortcomings: {
          all: internalAllRemarks,
          include: includedRemarks,
          exclude: excludedRemarks,
        },
      }

      createEliminationResult({
        projectId,
        prescriptionId,
        body: dataForRequest,
      })
    },
    [nextNumber],
  )

  const { formik } = useForm({
    validationSchema: validationDocument,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  // Включаем
  useEffect(() => {
    if (!data.length) return
    const { includedRemarks, internalAllRemarks } = values || {}

    if (includedRemarks.length === data.length) {
      setFieldValue('externalAllRemarks', true)
      setFieldValue('internalAllRemarks', true)

      values.excludedRemarks.length && setFieldValue('excludedRemarks', [])
      values.includedRemarks.length && setFieldValue('includedRemarks', [])
    }

    if (includedRemarks.length) setFieldValue('isAnyRemarksSelected', true)
    else setFieldValue('isAnyRemarksSelected', internalAllRemarks)
  }, [values.includedRemarks])

  // Выключаем
  useEffect(() => {
    if (!data.length) return
    const { internalAllRemarks, excludedRemarks } = values || {}

    if (internalAllRemarks) setFieldValue('externalAllRemarks', excludedRemarks.length ? false : true)

    if (excludedRemarks.length === data.length) {
      setFieldValue('externalAllRemarks', false)
      setFieldValue('internalAllRemarks', false)
      setFieldValue('isAnyRemarksSelected', false)

      values.excludedRemarks.length && setFieldValue('excludedRemarks', [])
    }
  }, [values.excludedRemarks])

  const changeAllRemarks = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!data.length) return
    const isChecked = e.target.checked

    setFieldValue('externalAllRemarks', isChecked)
    setFieldValue('internalAllRemarks', isChecked)
    setFieldValue('isAnyRemarksSelected', isChecked)

    setFieldValue('excludedRemarks', [])
    setFieldValue('includedRemarks', [])
  }

  const onChangeRemarkSelect = (e: React.ChangeEvent<HTMLInputElement>, remarkId: number) => {
    const isChecked = e.target.checked

    if (values.internalAllRemarks) {
      isChecked
        ? setFieldValue('excludedRemarks', [...values.excludedRemarks.filter((id) => id !== remarkId)])
        : setFieldValue('excludedRemarks', [...values.excludedRemarks, remarkId])
    } else {
      isChecked
        ? setFieldValue('includedRemarks', [...values.includedRemarks, remarkId])
        : setFieldValue('includedRemarks', [...values.includedRemarks.filter((id) => id !== remarkId)])
    }
  }

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    createEliminationResultResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Документ успешно создан.', { variant: 'success' })
      onChangeTab('view', true)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar('Документ с данным номером уже существует.', { variant: 'error' })
      }
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  return (
    <Stack height={'100%'} overflow={'auto'}>
      <FormikProvider value={formik}>
        <Stack component={Form} height={'100%'} justifyContent={'space-between'} overflow={'auto'}>
          <Stack spacing={2.5} height={'100%'} overflow={'auto'}>
            <FormWrapper
              onScroll={onScrollWithInfiniteLoad}
              spacing={1.5}
              padding={'0 7px 0 20px !important'}
              height={'100%'}
            >
              {isNextNumberLoading ? (
                <Progress />
              ) : (
                <>
                  <FieldItem
                    title='Дата акта об устранении'
                    icon={<DateIcon fontSize={'medium'} color={'secondary'} />}
                    isRequired={true}
                  >
                    <FieldForm
                      version='date'
                      name='date'
                      placeholder={!false ? 'дд.мм.гггг' : ' '}
                      helperText=''
                      dataValue={values.date}
                      onDataChange={(value: Date | null) => setFieldValue('date', value)}
                      style={{ maxWidth: '185px', width: '100%' }}
                      dateFieldProps={{
                        minDate: values.prescriptionDateStart || undefined,
                        maxDate: new Date() || undefined,
                        readOnly: false,
                      }}
                      inputProps={{
                        style: { textAlign: 'end', padding: '6px' },
                      }}
                    />
                  </FieldItem>
                  <FieldItem
                    title='Номер'
                    icon={<ErrorOutlineIcon fontSize={'medium'} color={'secondary'} />}
                    isRequired={true}
                  >
                    <FieldForm
                      version='project'
                      placeholder='123'
                      helperText=''
                      name='number'
                      fullWidth={false}
                      inputProps={{
                        style: { padding: '5px 8px', width: '167px', textAlign: 'end' },
                      }}
                    />
                  </FieldItem>
                  <FieldForm
                    version='project'
                    name={'comment'}
                    placeholder={'Комментарий'}
                    multiline
                    helperText=''
                    inputProps={{
                      style: {
                        minHeight: '106px',
                      },
                    }}
                  />

                  <Stack spacing={1} height={'100%'}>
                    <FieldItem title='Замечания' isRequired={true}>
                      <></>
                    </FieldItem>

                    <Divider />

                    {isSelectableRemarksLoading ? (
                      <Progress />
                    ) : data.length ? (
                      <>
                        <Stack direction={'row'} alignItems={'center'} paddingLeft={'4px'}>
                          <StyledCheckbox
                            id='allRemarks'
                            checked={values.externalAllRemarks}
                            onChange={changeAllRemarks}
                          />
                          <label htmlFor='allRemarks' style={{ cursor: 'pointer' }}>
                            <Typography variant='body2' color={theme.palette.primary.main}>
                              Выбрать все
                            </Typography>
                          </label>
                        </Stack>
                        <Stack spacing={1.25} height={'100%'}>
                          {data.map((remark) => (
                            <SelectableRemarkWrapper key={remark.id}>
                              <StyledCheckbox
                                id={String(remark.id)}
                                checked={
                                  (values.internalAllRemarks || values.includedRemarks.includes(remark.id)) &&
                                  !values.excludedRemarks.includes(remark.id)
                                }
                                onChange={(e) => onChangeRemarkSelect(e, remark.id)}
                                style={{ padding: '11px 6px 11px 11px', marginRight: 0 }}
                              />
                              <DocumentRemarkItem remark={remark} isSelectable={true} />
                            </SelectableRemarkWrapper>
                          ))}
                        </Stack>
                      </>
                    ) : (
                      <RemarkWarning variant='body1'>Замечания отсутствуют.</RemarkWarning>
                    )}
                  </Stack>
                </>
              )}
            </FormWrapper>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width={'100%'}>
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  loading={isFormLoading}
                  color='success'
                  size='medium'
                  fullWidth
                >
                  Сохранить
                </Button>
                <Button size='medium' fullWidth onClick={() => onClose(dirty)}>
                  Закрыть
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>
    </Stack>
  )
}
