import { emptyRemarksTxt } from '../../BindRemarksDrawer.text'
import { BindRemarksFormData } from '../BindRemarksForm'
import { ObjectsAllItemWrapper } from '../ObjectsList/ObjectsList.styles'
import { RemarkItem } from '../RemarkItem'
import { RemarksListWrapper } from './RemarksList.styles'
import { RemarksListProps } from './RemarksList.types'
import { Button, Stack, Typography } from '@mui/material'
import { useGetBindRemarksQuery } from 'api/prescriptions'
import { BindRemark } from 'api/prescriptions/types'
import { Divider } from 'components/Divider'
import { FieldForm } from 'components/FieldForm'
import { Progress } from 'components/Progress'
import { FieldArray, useFormikContext } from 'formik'
import React, { ChangeEvent, FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const RemarksList: FC<RemarksListProps> = ({
  onSubmit,
  onClose,
  searchValue,
  localDirty,
  rootChoice,
  objectList,
}) => {
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams<{
    projectId: string
    prescriptionId?: string
  }>()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString) || undefined

  const { values: formValues, setFieldValue } = useFormikContext<BindRemarksFormData>()
  const { choice, viewObjectId, filter } = formValues

  const { data: getBindRemarksResponse, isLoading } = useGetBindRemarksQuery(
    {
      projectId,
      prescriptionId,
      object: viewObjectId!,
      body: {},
      query: searchValue || undefined,
      senderUser: filter.senderUser,
      senderRepresentative: filter.senderRepresentative,
      contractor: filter.contractor || undefined,
    },
    { skip: !viewObjectId },
  )

  const { data } = getBindRemarksResponse || {}

  // const [getBindRemarks, { isLoading, data: remarksData }] = useGetBindRemarksMutation()
  // const { data } = remarksData || {}

  // @ts-ignore
  const allRemarksChecked =
    (!!formValues.choice.all || !!formValues.choice.includedObjects[viewObjectId!]?.all) &&
    !formValues.choice.excludedObjects.includes(viewObjectId!) &&
    !formValues.choice.includedObjects[viewObjectId!]?.exclude?.length
  // const [allRemarksChecked, setAllRemarksChecked] = useState<boolean>(false)

  // useLayoutEffect(() => {
  //   viewObjectId &&
  //     getBindRemarks({
  //       projectId,
  //       prescriptionId,
  //       object: viewObjectId,
  //       body: {},
  //       query: searchValue || undefined,
  //     })
  // }, [projectId, viewObjectId, searchValue])

  const onSave = () => {
    onSubmit(formValues)
    onClose(false, true)
  }

  if (!viewObjectId || !data?.length) return null
  // if (!viewObjectId || (searchValue && !data?.length)) return null

  const filtredList = () => {
    let chosenRemarks: BindRemark[] = []
    let unchosenRemarks: BindRemark[] = []

    data?.forEach((remark) => {
      const { shortcoming } = remark
      const { id, object } = shortcoming
      const { id: objectId } = object

      const objectIsExcluded = choice?.excludedObjects.includes(objectId)
      const remarkIsIncluded = !!choice?.includedObjects[objectId]?.include?.includes(id)
      const remarkIsExcluded = choice?.includedObjects[objectId]?.exclude?.includes(id)

      const objectChecked = choice?.all
        ? !objectIsExcluded && (choice?.includedObjects[objectId] ? choice?.includedObjects[objectId]?.all : true)
        : choice?.includedObjects[objectId]?.all

      const checked = objectChecked ? !remarkIsExcluded : remarkIsIncluded

      checked ? chosenRemarks.push(remark) : unchosenRemarks.push(remark)
    })

    return [...chosenRemarks, ...unchosenRemarks]
  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const checked = e.target.checked

    if (choice.all) {
      checked
        ? setFieldValue(
          'choice.excludedObjects',
          formValues.choice.excludedObjects.filter((objectId) => objectId !== viewObjectId),
        )
        : setFieldValue('choice.excludedObjects', [...formValues.choice.excludedObjects, viewObjectId])
    } else {
      setFieldValue(`choice.includedObjects[${viewObjectId!}]`, { all: checked, include: [], exclude: [] })
    }
  }

  const selectAllRemarksByObject = () => {
    setFieldValue(`choice.includedObjects[${viewObjectId!}]`, { all: true, include: [], exclude: [] })
  }

  return (
    <Stack pl={1.25} minWidth={350}>
      {isLoading ? (
        <Progress />
      ) : !data?.length ? (
        <Stack textAlign={'center'} height={'100%'}>
          {emptyRemarksTxt}
        </Stack>
      ) : (
        <Stack overflow={'auto'} spacing={1} height={'100%'}>
          {/* <Stack paddingRight={2} spacing={1}>
            <FieldForm
              version='checkbox'
              name={``}
              checkboxData={{
                label: (
                  <Typography variant='subtitle2' fontWeight={400} color={theme.palette.text.dark} marginLeft={'-10px'}>
                    Выбрать все замечания
                  </Typography>
                ),
                checked: allRemarksChecked,
                onChange: (e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e),
                onLabelClick: (e) => e.stopPropagation(),
              }}
            />
            <Divider />
          </Stack> */}
          <FieldArray
            name=''
            validateOnChange={false}
            render={() => {
              return (
                <RemarksListWrapper divider={<Divider />}>
                  {filtredList().map((remark) => (
                    <RemarkItem remarkData={remark} remarkList={data} objectList={objectList} />
                  ))}
                </RemarksListWrapper>
              )
            }}
          />
        </Stack>
      )}

      {!isLoading && (
        <Stack direction='row' p={2.5} pl={0}>
          <Button onClick={onSave} disabled={!localDirty} fullWidth variant='contained' size='small' color='success'>
            Сохранить
          </Button>
          <Button onClick={() => onClose(true, true)} sx={{ ml: 2 }} fullWidth variant='contained' size='small'>
            Закрыть
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
