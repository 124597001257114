import { SectionModalProps } from './SectionModal.types'
import { Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import {
  useCreateRegulationSectionMutation,
  useEditRegulationSectionMutation,
  useGetRegulationSectionQuery,
} from 'api/regulations'
import { Button } from 'components/Button'
import { ProjectFormTextField } from 'components/FieldForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'

export const SectionModal: FC<SectionModalProps> = ({ sectionId, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [sectionName, setSectionName] = useState<string>('')

  const { data } = useGetRegulationSectionQuery({ sectionId: sectionId! }, { skip: !sectionId })

  const dirty = !(sectionId ? sectionName === data?.name : !sectionName)

  useEffect(() => {
    if (data?.id) {
      setSectionName(data?.name)
    }
  }, [data])

  const onSectionNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSectionName(e.target.value)
  }

  const [createSection, createSectionResponse] = useCreateRegulationSectionMutation()
  const [editSection, editSectionResponse] = useEditRegulationSectionMutation()

  const onSubmit = () => {
    sectionId ? editSection({ sectionId, name: sectionName }) : createSection({ name: sectionName })
  }

  useMutationHandlers(
    createSectionResponse,
    () => {
      enqueueSnackbar('Раздел успешно добавлен.', { variant: 'success' })
      onClose(false)
    },
    (error: any) => {
      if (error?.originalStatus === 409) {
        enqueueSnackbar('Раздел с таким названием уже существует.', { variant: 'error' })
      }
    },
  )

  useMutationHandlers(
    editSectionResponse,
    () => {
      enqueueSnackbar('Раздел успешно изменён.', { variant: 'success' })
      onClose(false)
    },
    (error: any) => {
      if (error?.originalStatus === 409) {
        enqueueSnackbar('Раздел с таким названием уже существует.', { variant: 'error' })
      }
    },
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose(dirty)}
      PaperProps={{
        style: {
          width: 400,
          textAlign: 'center',
        },
      }}
    >
      <DialogTitle style={{ justifyContent: 'center', paddingTop: 30, paddingBottom: 12 }}>
        <Typography variant='subtitle1'>{sectionId ? 'Редактирование раздела' : 'Добавление раздела'}</Typography>
      </DialogTitle>

      <ProjectFormTextField
        value={sectionName}
        onChange={onSectionNameChange}
        sx={{ mb: '8px', px: '30px' }}
        placeholder='Введите название раздела'
        fullWidth
        variant='filled'
        size='small'
        autoComplete='off'
        InputProps={{ disableUnderline: true }}
      />

      <DialogActions style={{ justifyContent: 'center', paddingBottom: 30 }}>
        <Button onClick={onSubmit} disabled={!dirty} autoFocus color='success' fullWidth style={{ maxWidth: 165 }}>
          Сохранить
        </Button>
        <Button onClick={() => onClose(dirty)} fullWidth style={{ maxWidth: 165 }}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
