import { useSearchParams as useSearchParamsHook } from 'react-router-dom'

export type TSearchParam = 'role'
export type TAllSearchParams = Record<TSearchParam, string | number | null>

export default function useSearchParams() {
  const [searchParams, setSearchParams] = useSearchParamsHook()

  const get = (key: TSearchParam): string | null => {
    return searchParams.get(key)
  }

  const getAll = (): TAllSearchParams => {
    const params: TAllSearchParams = {} as TAllSearchParams

    searchParams.forEach((value, key) => {
      params[key as TSearchParam] = Number(value) || value
    })

    return params
  }

  const getSearch = (): string => {
    const newParams = new URLSearchParams(searchParams)

    return `?${newParams.toString()}`
  }

  const set = (key: TSearchParam, value: any): string => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set(key, String(value))
    setSearchParams(newParams)

    return `?${newParams.toString()}`
  }

  const del = (key: TSearchParam): string => {
    const newParams = new URLSearchParams(searchParams)
    newParams.delete(key)
    setSearchParams(newParams)

    return `?${newParams.toString()}`
  }

  const handlers = {
    get,
    getAll,
    getSearch,
    set,
    del,
  }

  return handlers
}
