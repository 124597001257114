import { AnalitycsFilterFormData, AnalyticsFilterFormProps } from './AnalyticsFilterForm.types'
import { MultiSelect } from './MultiSelect'
import { validationAnalyticsFilter } from './validation'
import { HelpOutline as HelpIcon } from '@mui/icons-material'
import { Divider, Stack, Typography } from '@mui/material'
import {
  useGetContractorsForAnalyticsFilterQuery,
  useGetProjectsForAnalyticsFilterQuery,
  useSaveAnalyticsFilterMutation,
} from 'api/analytics'
import { AnalyticsSettings } from 'api/analytics/types'
import { Button } from 'components/Button'
import { FieldForm } from 'components/FieldForm'
import { ClearText } from 'components/FilterDrawer/FilterDrawer.styles'
import { Tooltip } from 'components/Tooltip'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { analyticsSettingsSelector } from 'store/slices/analytics/selectors'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const AnalyticsFilterForm: FC<AnalyticsFilterFormProps> = ({ onFormChange, onClose }) => {
  const { filters } = useTypedSelector(analyticsSettingsSelector)
  const { enqueueSnackbar } = useSnackbar()
  const { data: projectsData } = useGetProjectsForAnalyticsFilterQuery({})
  const { data: contractorsData } = useGetContractorsForAnalyticsFilterQuery({})

  const { data: projectsList = [] } = projectsData || {}
  const { data: contractorsList = [] } = contractorsData || {}

  const [saveAnalyticsFilter, { isLoading: isAnalyticsFilterSaving, ...saveAnalyticsFilterResponse }] =
    useSaveAnalyticsFilterMutation()

  const isQuerying = isAnalyticsFilterSaving
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { after, before, projects, contractors } = filters || {}

  const analyticsFilterEmptyValues: AnalitycsFilterFormData = useMemo(
    () => ({
      dateAfter: null,
      dateBefore: null,
      projects: projectsList.map((project) => project.id) || [],
      contractors: contractorsList.map((contractor) => contractor.id) || [],
    }),
    [projectsList, contractorsList],
  )

  const initialValues: AnalitycsFilterFormData = useMemo(
    () => ({
      dateAfter: parseResponseDate(after || '').date || null,
      dateBefore: parseResponseDate(before || '').date || null,
      projects: projects || projectsList.map((project) => project.id) || [],
      contractors: contractors || contractorsList.map((contractor) => contractor.id) || [],
    }),
    [filters, projectsList, contractorsList],
  )

  const onSubmit = useCallback(
    (values: AnalitycsFilterFormData) => {
      const { dateAfter, dateBefore, projects, contractors } = values

      let projectsForRequest: number[] | null = projects
      let allProjectsSelected = projects.length === projectsList.length

      let contractorsForRequest: number[] | null = contractors
      let allContractorsSelected = contractors.length === contractorsList.length

      if (allProjectsSelected || !projects.length) projectsForRequest = null
      if (allContractorsSelected || !contractors.length) contractorsForRequest = null

      const dataForRequest: AnalyticsSettings = {
        filters: {
          after: formatDateForServer(dateAfter) || null,
          before: formatDateForServer(dateBefore) || null,
          deviation: null,
          projects: projectsForRequest,
          contractors: contractorsForRequest,
        },
        widgets: [],
      }

      saveAnalyticsFilter({ body: dataForRequest })
    },
    [projectsList, contractorsList],
  )

  const { formik } = useForm({
    validationSchema: validationAnalyticsFilter,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid, resetForm, setValues } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const onFilterClear = () => {
    setValues(analyticsFilterEmptyValues)
  }

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    saveAnalyticsFilterResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Фильтр успешно применён.', { variant: 'success' })
      onClose(false, true)
    },
    () => handleError(),
  )

  return (
    <Stack height={'100%'} overflow={'auto'}>
      <FormikProvider value={formik}>
        <Stack component={Form} height={'100%'} justifyContent={'space-between'} overflow={'auto'}>
          <Stack spacing={2.5} height={'100%'} overflow={'auto'}>
            <FormWrapper spacing={1.5} padding={'0 7px 0 20px !important'} height={'100%'}>
              <Stack padding={'12px 20px'} alignItems={'flex-end'}>
                <ClearText
                  isDisabled={isEqual(values, analyticsFilterEmptyValues)}
                  onClick={onFilterClear}
                  variant='subtitle2'
                >
                  Сбросить
                </ClearText>
              </Stack>
              <Stack spacing={2}>
                <FieldItem title='Период:' isRequired={false}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <FieldForm
                      version='date'
                      name='dateAfter'
                      placeholder={'дд.мм.гггг'}
                      helperText=''
                      dataValue={values.dateAfter}
                      onDataChange={(value: Date | null) => setFieldValue('dateAfter', value)}
                      style={{ maxWidth: '137px', width: '100%' }}
                      inputProps={{
                        style: { height: '36px' },
                      }}
                      dateFieldProps={{
                        maxDate: values.dateBefore || new Date(),
                      }}
                    />
                    <Typography variant='body2'>—</Typography>
                    <FieldForm
                      version='date'
                      name='dateBefore'
                      placeholder={'дд.мм.гггг'}
                      helperText=''
                      dataValue={values.dateBefore}
                      onDataChange={(value: Date | null) => setFieldValue('dateBefore', value)}
                      style={{ maxWidth: '137px', width: '100%' }}
                      inputProps={{
                        style: { height: '36px' },
                      }}
                      dateFieldProps={{
                        // disabled: values.status !== 'COMPLETE',
                        minDate: values.dateAfter || undefined,
                        maxDate: new Date() || undefined,
                        // readOnly: viewingOnly || isEngineerMode,
                      }}
                    />
                  </Stack>
                </FieldItem>

                <Divider />

                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography variant='body2' lineHeight={'inherit'}>
                      Проект:
                    </Typography>
                    <Tooltip
                      title={'В рамках предоставленных пользователю прав.'}
                      bgColor={theme.palette.bg.white}
                      textColor={theme.palette.text.dark}
                    >
                      <HelpIcon color='disabled' fontSize='medium' />
                    </Tooltip>
                  </Stack>

                  <MultiSelect name='projects' dropdownList={projectsList} />
                </Stack>

                <Divider />

                <FieldItem title='Организация, получившая предписание:' isRequired={false} direction={'column'}>
                  <MultiSelect name='contractors' dropdownList={contractorsList} />
                </FieldItem>
                <Divider />
              </Stack>
            </FormWrapper>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width={'100%'}>
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  loading={isFormLoading}
                  color='success'
                  size='medium'
                  fullWidth
                >
                  Сохранить
                </Button>
                <Button size='medium' fullWidth onClick={() => onClose(dirty)}>
                  Закрыть
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>
    </Stack>
  )
}
