import { AdminLegend } from '../components/AdminLegend/AdminLegend'
import { UserCardContent } from '../components/UserCardContent'
import { CardsFullWrapper, CardsWrapper, FullUserCardWrapper } from './AdminUsers.styles'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import { useGetUsersQuery } from 'api/users'
import { GetUsersResponse } from 'api/users/api.types'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress/Progress'
import { TabData } from 'components/Tabs'
import {
  UserRoleByTabLabel,
  UserRoleRuByTabLabel,
  UserRoleTabLabels,
  UserRoles,
  userRolesEnToRu,
  userTabNamesArray,
} from 'core/types/user'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useSearch } from 'hooks/useSearch'
import useSearchParams from 'hooks/useSearchParams'
import { getEmptyPageData } from 'pages/Projects'
import React, { UIEvent, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { currentCompanyIdSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { throttle } from 'throttle-debounce'
import { NUMBER_OF_USERS_TO_FETCH, XXL_FOR_LAYOUT } from 'utils/constants'

export const AdminUsers: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const navigate = useNavigate()
  const currentCompanyId = useTypedSelector(currentCompanyIdSelector)
  const searchHandlers = useSearchParams()
  const roleTab = searchHandlers.get('role') as UserRoles

  const { searchValue } = useSearch()

  const tabNames: UserRoleTabLabels[] = userTabNamesArray

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: UserRoleTabLabels) => {
    if (tabValue !== 'Все пользователи') searchHandlers.set('role', UserRoleByTabLabel[tabValue])
    else searchHandlers.del('role')

    setCurrentTab(tabValue)
  }, [])

  useBreadcrumbs([{ title: 'Управление пользователями' }])

  const onAddUserClick = () => {
    navigate('add' + searchHandlers.getSearch())
  }

  const [offset, setOffset] = useState<number>(0)

  const [currentTab, setCurrentTab] = useState<UserRoleTabLabels>(
    roleTab ? UserRoleRuByTabLabel[roleTab] : 'Все пользователи',
  )

  const { data, isLoading, isFetching } = useGetUsersQuery({
    companyId: currentCompanyId,
    limit: NUMBER_OF_USERS_TO_FETCH,
    offset,
    query: searchValue || undefined,
    role: currentTab === 'Все пользователи' ? undefined : UserRoleByTabLabel[currentTab],
  })

  const { data: usersData, total } = data || ({} as GetUsersResponse)

  const fetchNextPage = () => {
    setOffset((prevLimit) => prevLimit + NUMBER_OF_USERS_TO_FETCH)
  }

  const hasNextPage = usersData?.length < total

  const debouncedOnScroll = useCallback(
    throttle(300, (e: UIEvent<HTMLDivElement>) => fetchMoreOnBottomReached(e.target as HTMLDivElement), {
      noTrailing: true,
    }),
    [isFetching],
  )

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        if (scrollHeight - scrollTop - clientHeight < 800 && !isFetching && hasNextPage) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, hasNextPage],
  )

  const onUserCardClick = (userId: number) => {
    navigate(`edit/${userId}` + searchHandlers.getSearch())
  }

  const tabsData: TabData<UserRoleTabLabels>[] = useMemo(() => {
    return tabNames.map((tabName) => ({ value: tabName, label: tabName }))
  }, [tabNames])

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют пользователи, соответствующие результатам запроса.</>,
  )

  return (
    <Stack flex={1}>
      <AdminLegend<UserRoleTabLabels>
        currentTab={currentTab}
        tabsData={tabsData}
        onTabChange={onTabChange}
        onAddClick={onAddUserClick}
      />
      {isLoading ? (
        <Progress />
      ) : usersData?.length ? (
        <CardsFullWrapper onScroll={debouncedOnScroll}>
          <CardsWrapper spacing={2.5} container>
            {usersData?.map((user) => (
              <Grid item xs={12} md={6} lg={4} xl={xxl ? 2 : 3} container justifyContent='center' key={user.id}>
                <FullUserCardWrapper onClick={() => onUserCardClick(user.id)} textColor='dark'>
                  <UserCardContent userData={user} mode='full' />
                </FullUserCardWrapper>
              </Grid>
            ))}
          </CardsWrapper>
        </CardsFullWrapper>
      ) : (
        <EmptyPage data={emptyFilteredData} forFilter />
      )}
    </Stack>
  )
}
