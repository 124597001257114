import { AuditDrawer } from '../AuditDrawer'
import { dateRangeKeys } from './RemarksTable.constants'
import {
  TABLE_CELL_HEIGHT,
  RemarksTableBody,
  RemarksTableCell,
  RemarksTableContainer,
  RemarksTableHead,
  RemarksTableRow,
  ObserverBound,
} from './RemarksTable.styles'
import {
  CustomColumnDef,
  NotificationData,
  RemarkTableDialogTrigger,
  RemarksTableProps,
  getStatusColumn,
} from './RemarksTable.types'
import { AuditCell } from './cells/AuditCell'
import { FilterAlt as FilterIcon, KeyboardArrowDown as ArrowIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import {
  remarksApi,
  useLazyEditRemarkStatusQuery,
  useGetRemarksFilteredListQuery,
  useGetRemarksColumnSettingsQuery,
  useSetRemarksColumnSettingsMutation,
  useGetRemarksByPrevIdQuery,
  useLazyCheckRemarkStatusForEmailNotificationQuery,
} from 'api/remarks'
import { RemarksFilters, remarkColumnByValue } from 'api/remarks/types'
import { RemarkFull, RemarkStatuses } from 'api/remarks/types'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { FilterDrawer } from 'components/FilterDrawer'
import { Progress } from 'components/Progress'
import { SortingOrder } from 'core/types/prescription'
import {
  filterableColumns,
  rangingDates,
  RemarkFilterableColumn,
  SortableColumn,
  sortableColumns,
} from 'core/types/remarks'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useObserver } from 'hooks/useObserver'
import { useSnackbar } from 'notistack'
import {
  IconButtonWrapper,
  TableCell,
  TableCellWrapper,
} from 'pages/Documents/components/DocumentsTable/DocumentsTable.styles'
import { TableWrapper } from 'pages/Ksg/components/WorkTable/WorkTable.styles'
import { getEmptyPageData } from 'pages/Projects'
import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router-dom'
import { remarksShouldResetPageSelector } from 'store/slices/infiniteScroll'
import { useAppDispatch } from 'store/store'
import { SortButton } from 'styles/commonTableStyles'
import { theme } from 'styles/theme'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export const RemarksTable: FC<RemarksTableProps> = ({ projectId, sort, filter }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const [notificationData, setNotificationData] = useState<NotificationData | null>(null)
  const [setRemarksColumnSettings, setRemarksColumnSettingsResponse] = useSetRemarksColumnSettingsMutation({
    fixedCacheKey: 'columns',
  })

  const { sortableColumn, setSortableColumn } = sort || {}
  const { filterData, setFilterData } = filter || {}

  // Location
  const location = useLocation()
  const locationState = location.state || {}
  const { remarkId: locationRemarkId, remarksPage } = locationState || ({} as any)

  // Filter
  const [filterDrawerData, setFilterDrawerData] = useState({
    label: '',
    columnId: '',
    open: false,
  })

  // Audit
  const [openedAuditInfo, setOpenedAuditInfo] = useState<{
    id: number
    primaryId?: number | null
    remarkNumber?: string | null
  } | null>(null)

  const onAuditOpen = (
    id: number,
    index?: number | undefined,
    primaryId?: number | null | undefined,
    remarkNumber?: string | null,
  ) => {
    setOpenedAuditInfo({ id: id, primaryId: primaryId, remarkNumber: remarkNumber })
  }

  const onAuditClose = () => {
    setOpenedAuditInfo(null)
  }

  // Status cell
  const [editRemarkStatus, editRemarkStatusResponse] = useLazyEditRemarkStatusQuery()
  const [checkRemarkStatusForEmailNotification, { ...checkRemarkStatusForEmailNotificationResult }] =
    useLazyCheckRemarkStatusForEmailNotificationQuery()

  const onStatusSelect = (
    projectId: number,
    remarkId: number,
    primary: number | null,
    status: RemarkStatuses,
    dateDone: string,
    isShouldNotify = false,
  ) => {
    const today = new Date()
    const isShouldSetDateDone = !dateDone && status === 'COMPLETE'

    if (status !== 'COMPLETE' && dateDone)
      enqueueSnackbar(
        'Замечание имеет факт. дату устранения. Измените статус на Исполнено, либо удалите факт. дату устранения.',
        { variant: 'error' },
      )
    else {
      //Возможно, эту проверку на статусы можно убрать
      if (status === 'COMPLETE' || status === 'DISCARDED') {
        if (notificationData) setNotificationData(null)
        else {
          setNotificationData({
            status,
            remarkId,
            primary,
            dateDone,
          })
          checkRemarkStatusForEmailNotification({
            projectId,
            remarkId: primary || remarkId,
            secondaryId: primary ? remarkId : null,
            status: status,
            completion: isShouldSetDateDone ? formatDateForServer(today) : undefined,
          })
          return
        }
      }

      editRemarkStatus({
        projectId,
        remarkId: primary || remarkId,
        secondaryId: primary ? remarkId : null,
        status,
        notification: isShouldNotify,
        dateDone: isShouldSetDateDone ? formatDateForServer(today) : undefined,

        sort: sortableColumn || undefined,
        filter: filterData,
      })
    }
  }

  useMutationHandlers(checkRemarkStatusForEmailNotificationResult, (data) => {
    if (data.data) {
      setConfirmDialogTrigger('notification')
      openConfirm()
    } else {
      const { status, remarkId, primary, dateDone } = notificationData as NotificationData
      onStatusSelect(projectId, remarkId, primary, status, dateDone || '', false)
    }
  })

  const { data: columnsData } = useGetRemarksColumnSettingsQuery({ projectId })
  const columnHelper = createColumnHelper<RemarkFull>()

  const auditColumn: CustomColumnDef<RemarkFull> = {
    ...columnHelper.display({
      id: 'actions',
      minSize: 50,
      size: 50,
      maxSize: 50,
      cell: (info) => <AuditCell info={info} onAuditOpen={onAuditOpen} />,
    }),
    isCustomCell: true,
  }

  const [columns, setColumns] = useState<CustomColumnDef<RemarkFull>[]>([])

  useEffect(() => {
    const { columns: selectedColumns = [] } = columnsData || {}
    const tempColumns = []

    selectedColumns?.forEach((selectedColumn) => {
      if (selectedColumn === 'STATUS') {
        tempColumns.push(getStatusColumn(onStatusSelect))
        return
      }

      tempColumns.push(remarkColumnByValue[selectedColumn])
    })

    tempColumns.push(auditColumn)
    setColumns(tempColumns)
  }, [columnsData, sort, filter])

  useMutationHandlers(setRemarksColumnSettingsResponse, (data) => {
    if (data.showSecondary !== columnsData?.showSecondary) {
      // dispatch(setShouldResetPage({ table: 'remarks', shouldResetPage: true }))

      setFilterData((prev: RemarksFilters) => ({
        ...(prev as RemarksFilters),
        type: data.showSecondary ? null : ['PRIMARY'],
      }))
      if (sortableColumn?.column === 'type') setSortableColumn(null)
    }
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  // Observer
  const [bottomBound, inBottomBound] = useInView()
  const [topBound, inTopBound] = useInView()

  const { queryData, fetchMoreOnTopReached, fetchMoreOnBottomReached, currentPage, onRefetch } = useObserver({
    refElement: tableContainerRef,
    api: remarksApi,
    tagName: 'Remarks',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    shouldResetPageSelector: remarksShouldResetPageSelector,
    query: useGetRemarksByPrevIdQuery,
    startId: remarksPage || null,
    arg: {
      projectId,
      sort: sortableColumn,
      filter: filterData,
    },
    table: 'remarks',
  })

  const { data = [] } = queryData.data || {}
  const { isLoading } = queryData || {}

  useEffect(() => {
    if (inTopBound) fetchMoreOnTopReached()
  }, [inTopBound])

  useEffect(() => {
    if (inBottomBound) fetchMoreOnBottomReached()
  }, [inBottomBound])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef?.current!,
  })

  const onRowClick = (id: number) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(`edit/${id}`, { state: { sortableColumn, filterData, currentPage, shouldResetLocationState: true } })
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <Stack>
      <div>Замечания отсутствуют.</div>
      <div>Создание замечаний возможно только после заполнения справочников проекта.</div>
      <div>
        Перед созданием замечаний добавьте, пожалуйста, данные в справочники проекта: Объекты, Виды работ, Участники
        проекта.
      </div>
    </Stack>,
  )

  const onSortColumn = (columnId: SortableColumn) => {
    let order: SortingOrder = null

    if (sortableColumn?.column === columnId || !sortableColumn) {
      if (!sortableColumn?.order) order = 'ASC'
      else if (sortableColumn.order === 'ASC') order = 'DESC'
    } else order = 'ASC'

    if (sortableColumn?.order === 'DESC' && sortableColumn?.column === columnId) setSortableColumn(null)
    else setSortableColumn({ column: columnId, order: order })
  }

  const onFilterDrawerOpen = (columnId: RemarkFilterableColumn, columnName: string) => {
    setFilterDrawerData({
      label: columnName,
      columnId: columnId,
      open: true,
    })
  }

  const onFilterDrawerClose = () => {
    setFilterDrawerData({ ...filterDrawerData, open: false })
  }

  // Positioning
  const [highlightedId, setHighlightedId] = useState<number | null>(null)
  const [showObserverBounds, setShowObserverBounds] = useState(locationRemarkId && data.length ? false : true)

  useEffect(() => {
    if (highlightedId) setShowObserverBounds(true)
  }, [highlightedId])

  useEffect(() => {
    if (locationRemarkId) {
      const rowIndex = data.findIndex((item) => item.id === locationRemarkId)
      if (rowIndex >= 0) {
        setTimeout(() => scrollToItem(rowIndex), 500)

        setHighlightedId(locationRemarkId)
      }
    }
  }, [locationRemarkId])

  const onHoverRow = (rowId: number) => {
    if (highlightedId === rowId) setHighlightedId(null)
  }

  const scrollToItem = (index: number) => {
    if (index >= 0) virtualizer.scrollToIndex(index + 1, { align: 'center', behavior: 'auto' })
  }

  const handleNotificationConfirm = useCallback(
    (confirm: boolean) => {
      const { status, remarkId, primary, dateDone } = (notificationData as NotificationData) || {}
      if (confirm) onStatusSelect(projectId, remarkId, primary, status, dateDone || '', true)
      else onStatusSelect(projectId, remarkId, primary, status, dateDone || '', false)
    },
    [notificationData],
  )

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<RemarkTableDialogTrigger>('notification')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    notification: {
      handleConfirm: handleNotificationConfirm,
      title: 'Отправить уведомление?',
      body: (
        <Typography variant='body2' color={theme.palette.text.dark}>
          Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.
        </Typography>
      ),
      denyButtonText: 'нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const handleError = useCallback(() => {
    enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
  }, [])

  useMutationHandlers(
    editRemarkStatusResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Статус успешно изменён.', { variant: 'success' })
      onRefetch(data.data.id)
    },
    () => handleError(),
  )

  return (
    <>
      {isLoading && <Progress />}

      {!isLoading && !!data.length && (
        <TableWrapper>
          <RemarksTableContainer ref={tableContainerRef}>
            <RemarksTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <RemarksTableRow style={{ zIndex: '10' }} key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    let headerId = header.id as SortableColumn

                    let isSortableColumn = sortableColumns.includes(headerId)
                    let isFilterableColumn = filterableColumns.includes(headerId)

                    let order = null

                    if (isSortableColumn && sortableColumn?.column === header.id) {
                      order = sortableColumn?.order
                    }

                    if (headerId === 'type') {
                      isSortableColumn = !!columnsData?.showSecondary
                      isFilterableColumn = !!columnsData?.showSecondary
                    }

                    let isFilterApplied = !!filterData[header.id as RemarkFilterableColumn]?.length

                    // @ts-ignore
                    if (
                      rangingDates.includes(header.id as RemarkFilterableColumn) &&
                      filterData?.[`${header.id}_range` as keyof RemarksFilters]
                    )
                      isFilterApplied = true

                    return (
                      <RemarksTableCell
                        key={header.id}
                        style={{
                          flex: 1,
                          minWidth: header.column.columnDef.minSize,
                          width: header.column.columnDef.size,
                          maxWidth: header.column.columnDef.maxSize,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Stack width={24}>
                          {isSortableColumn && (
                            <SortButton onClick={() => onSortColumn(header.id as SortableColumn)} sorting={order}>
                              <ArrowIcon />
                              <ArrowIcon />
                            </SortButton>
                          )}
                        </Stack>
                        <Typography variant='body2'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Typography>
                        <Stack>
                          {isFilterableColumn && (
                            <IconButtonWrapper
                              onClick={() =>
                                onFilterDrawerOpen(
                                  header.id as RemarkFilterableColumn,
                                  header.column.columnDef.header as string,
                                )
                              }
                            >
                              {isFilterApplied ? (
                                <FilterIcon style={{ fill: theme.palette.text.light }} fontSize='inherit' />
                              ) : (
                                <FilterIcon
                                  style={{ fill: theme.palette.text.light, opacity: 0.5 }}
                                  fontSize='inherit'
                                />
                              )}
                            </IconButtonWrapper>
                          )}
                        </Stack>
                      </RemarksTableCell>
                    )
                  })}
                </RemarksTableRow>
              ))}
            </RemarksTableHead>

            <RemarksTableBody style={{ height: virtualizer.getTotalSize() }}>
              {showObserverBounds && (
                <div ref={topBound} style={{ position: 'absolute', top: 400, zIndex: 5, width: '500%' }}></div>
              )}
              {virtualizer.getVirtualItems().map((row) => {
                const remarkId = data[row.index].id

                return (
                  <RemarksTableRow
                    onClick={onRowClick(remarkId)}
                    showAnimation={remarkId === highlightedId}
                    onMouseEnter={() => onHoverRow(remarkId)}
                    key={row.key}
                    data-index={row.index}
                    ref={virtualizer.measureElement}
                    style={{
                      width: '100%',
                      transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                    }}
                  >
                    {columns.map((column, columnIndex) => {
                      const cell = rows[row.index].getVisibleCells()[columnIndex]
                      const { minSize, size, maxSize, textAlign } = column
                      const cellValue = flexRender(cell.column.columnDef.cell, cell.getContext()) ?? '—'

                      if (column.isCustomCell) {
                        return flexRender(cell.column.columnDef.cell, cell.getContext())
                      }

                      return (
                        <TableCellWrapper
                          key={column.id}
                          style={{
                            flex: 1,
                            minWidth: minSize,
                            width: size,
                            maxWidth: maxSize,
                          }}
                        >
                          <TableCell className='cell'>
                            <Typography variant='body2' width='100%' textAlign={textAlign}>
                              {cellValue}
                            </Typography>
                          </TableCell>
                        </TableCellWrapper>
                      )
                    })}
                  </RemarksTableRow>
                )
              })}
              {showObserverBounds && <ObserverBound ref={bottomBound}></ObserverBound>}
            </RemarksTableBody>

            <AuditDrawer
              variant='remarks'
              openedAuditId={openedAuditInfo?.id || null}
              openedAuditPrimaryId={openedAuditInfo?.primaryId || null}
              openedAuditRemarkNumber={openedAuditInfo?.remarkNumber || null}
              onClose={onAuditClose}
            />

            <FilterDrawer
              open={filterDrawerData.open}
              label={filterDrawerData.label}
              columnId={filterDrawerData.columnId as RemarkFilterableColumn}
              filterData={filterData}
              setFilterData={setFilterData}
              query={useGetRemarksFilteredListQuery}
              onClose={onFilterDrawerClose}
              api={remarksApi}
              tagName='Remarks'
              hasDateSelectionRange={dateRangeKeys.includes(filterDrawerData.columnId)}
              isRangingDate={rangingDates.includes(filterDrawerData.columnId as RemarkFilterableColumn)}
            />
          </RemarksTableContainer>
        </TableWrapper>
      )}

      {!isLoading && !data.length && <EmptyPage data={emptyPageData} fullPage />}

      <ConfirmDialog />
    </>
  )
}
